import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function SvgLogoLocationSmichov({id, ...rest}) {
    if (!id) {
        id = useMemo(() => randomString('logo-location-smichov'), []);
    }

    return (
        <svg viewBox="0 0 427 470" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M423.044 3.10017C427.965 7.54798 428.349 15.1431 423.901 20.0644L20.9216 465.94C16.4738 470.861 8.8787 471.245 3.95743 466.797C-0.963828 462.349 -1.34764 454.754 3.10017 449.833L406.08 3.95743C410.528 -0.963828 418.123 -1.34764 423.044 3.10017Z"
                  fill={`url(#${id})`}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.0561 123.603C24.6642 69.719 73.5976 24.0216 137.575 24.0216H414.991C421.624 24.0216 427.002 18.6442 427.002 12.0108C427.002 5.37742 421.624 0 414.991 0H137.575C62.802 0 0.76879 54.0593 0.0350955 123.413L0.0344238 123.476V123.54C0.0344238 192.348 63.788 247.08 137.61 247.08H213.518C220.151 247.08 225.529 241.702 225.529 235.069C225.529 228.435 220.151 223.058 213.518 223.058H137.61C74.3275 223.058 24.0962 176.524 24.0561 123.603Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M194.575 234.932C194.575 228.298 199.953 222.921 206.586 222.921H282.494C356.316 222.921 420.07 277.652 420.07 346.461V346.528L420.069 346.594C419.297 415.941 357.27 470 282.494 470H12.0452C5.41185 470 0.0344238 464.623 0.0344238 457.99C0.0344238 451.356 5.41185 445.979 12.0452 445.979H282.494C346.469 445.979 395.408 400.283 396.048 346.393C396.005 293.474 345.775 246.943 282.494 246.943H206.586C199.953 246.943 194.575 241.565 194.575 234.932Z"
                  fill="currentColor"/>
            <defs>
                <linearGradient id={id} x1="-1.71583" y1="234.966" x2="428.717" y2="234.966"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor" stopOpacity="0.8"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0.04"/>
                </linearGradient>
            </defs>
        </svg>

    )
}