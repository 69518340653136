import Image from "next/image";
import MapPin from "./MapPin";
import {locationData} from "../helpers/locations";

export default function Map({
                                fullMobile = false,
                                location = 'none',
                                size = 'square',
                                className = '',
                                children,
                                ...rest
                            }) {
    const mapImage = locationData[location].images.map[size];
    const pins = locationData[location].pins[size];

    return (
        <div
            className={`relative overflow-hidden ${fullMobile ? 'sm:rounded-md' : 'rounded-md'} ${className}`}
            {...rest}
        >
            <Image src={mapImage}
                   alt="Map"
            />
            <div className="overlay">
                {pins.map((pin, key) => {
                    return (
                        <MapPin {...pin}
                                type={locationData[location]?.status === 'closed' ? 'loading' : 'logo'}
                                key={`MapPin: ${key}`}
                        />
                    )
                })}
            </div>
        </div>
    )
}
