import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function SvgLogoLocationKarlin({id, ...rest}) {
    if(!id){
        id = useMemo(() => randomString('logo-location-karlin'), []);
    }

    return (
        <svg viewBox="0 0 46 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M45.3688 0.287958C45.9989 0.762953 46.1246 1.65876 45.6496 2.28881L2.56955 59.4313C2.09456 60.0613 1.19875 60.187 0.568702 59.712C-0.0613427 59.237 -0.187036 58.3412 0.287958 57.7112L43.368 0.568702C43.843 -0.0613427 44.7388 -0.187036 45.3688 0.287958Z"
                  fill={`url(#${id})`}
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.42867 0.000244141C2.21771 0.000244141 2.85735 0.639883 2.85735 1.42892V58.5714C2.85735 59.3604 2.21771 60.0001 1.42867 60.0001C0.639639 60.0001 0 59.3604 0 58.5714V1.42892C0 0.639883 0.639639 0.000244141 1.42867 0.000244141Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 30C0 29.2109 0.639639 28.5713 1.42867 28.5713H22.9687C23.4171 28.5713 23.8395 28.7818 24.1095 29.1399L45.6495 57.7111C46.1245 58.3412 45.9988 59.237 45.3688 59.712C44.7387 60.187 43.8429 60.0613 43.3679 59.4313L22.2566 31.4286H1.42867C0.639639 31.4286 0 30.789 0 30Z"
                  fill="currentColor"/>
            <defs>
                <linearGradient id={id} x1="-2.60744" y1="29.6769" x2="44.7101" y2="30.0286"
                                gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.04" stopColor="currentColor"/>
                    <stop offset="1" stopOpacity="0.8" stopColor="currentColor"/>
                </linearGradient>
            </defs>
        </svg>
    )
}