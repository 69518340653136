export default function SvgSnowflakesBg(props) {
    return (
        <svg viewBox="0 0 825 411" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_963_547)">
                <path
                    d="M95.4409 152.716L66.4503 145.123L58.3617 174.587L50.3298 145.104L21.3203 152.696L42.26 130.826L21.3392 108.955L50.3298 116.548L58.3995 87.1035L66.4503 116.567L95.4598 108.974L74.5201 130.845L95.4409 152.716Z"
                    stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M28.2344 79.26L87.4061 181.948" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M87.0333 79.6467L28.9766 182.122" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M0.0390625 131.561L115.963 130.035" stroke="currentColor" strokeWidth="5"
                      strokeMiterlimit="10"/>
                <path d="M21.0183 131.27L3.59375 112.471" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M4.51562 150.263L21.0142 131.271" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M14.5938 168.773L39.6156 163.054" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M47.2633 188.421L39.6094 163.054" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M69.3047 187.666L76.5807 163.226" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M100.655 167.998L76.5781 163.226" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M111.86 148.156L95.4375 130.594" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M111.293 111.139L95.4375 129.648" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M101.468 92.4375L77.8828 98.1564" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M69.4922 73.5417L76.0122 97.5764" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M46.5263 73.5417L39.6094 98.156" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M14.2344 92.051L39.6153 98.1563" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
            </g>
            <g clipPath="url(#clip1_963_547)">
                <path
                    d="M816.846 139.611L805.35 136.6L802.142 148.284L798.957 136.593L787.453 139.604L795.757 130.931L787.461 122.258L798.957 125.269L802.157 113.593L805.35 125.277L816.853 122.266L808.55 130.939L816.846 139.611Z"
                    stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M790.195 110.482L813.66 151.204" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M813.515 110.636L790.492 151.273" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M779.016 131.222L824.986 130.617" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M787.332 131.107L780.422 123.652" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M780.789 138.639L787.332 131.107" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M784.789 145.979L794.712 143.711" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M797.746 153.771L794.711 143.711" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M806.484 153.471L809.37 143.779" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M818.915 145.672L809.367 143.779" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M823.356 137.803L816.844 130.839" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M823.131 123.124L816.844 130.464" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M819.236 115.708L809.883 117.976" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M806.555 108.215L809.14 117.746" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M797.454 108.215L794.711 117.976" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M784.641 115.555L794.705 117.976" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
            </g>
            <g clipPath="url(#clip2_963_547)">
                <path d="M128.219 271.808L243.581 387.639" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M142.977 260.073V286.217" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M156.24 273.512L155.672 297.725" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M165.641 269.308L184.664 285.819" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M204.623 269.479L185.031 286.785" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M204.795 250.096L185.602 269.108" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M165.836 250.267L185.598 269.108" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M213.453 273.512L215.753 298.492" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M227.648 259.105L227.25 287.552" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M255.473 286.986L227.25 287.554" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M241.677 300.625L215.555 299.858" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M244.738 310.599L227.844 329.639" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M264.13 309.464L244.539 329.442" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M263.933 349.022L245.875 329.641" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M245.504 349.42L227.844 329.641" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M243.378 358.312L214.984 359.79" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M256.325 371.415L227.25 371.131" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M226.883 399.577L227.252 371.131" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M214.102 386.929L214.982 359.79" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M205.896 390.596L184.828 372.095" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M205.759 408.982L185.43 388.805" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M164.672 408.784L186.364 389.005" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M164.672 389.969L185.995 372.663" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M156.434 387.47L155.469 359.99" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M143.378 400.344L143.18 371.698" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M113.992 370.731L143.181 371.697" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M127.82 357.688L157.207 359.592" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M124.75 349.419L141.275 329.811" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M124.383 309.064L142.81 329.44" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M105.555 308.296L125.913 329.439" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M104.992 349.816L125.918 329.44" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M128.586 300.796L155.077 299.261" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M114.391 286.984L144.147 286.785" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M185.227 247L185.794 410.716" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M103.484 329.668L267.002 329.44" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M243.406 271.354L126.852 387.839" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
            </g>
            <g clipPath="url(#clip3_963_547)">
                <path d="M316.141 17.8494L399.145 101.192" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M326.758 9.40601V28.2173" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M336.299 19.0762L335.891 36.4971" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M343.062 16.0513L356.75 27.931" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M371.112 16.1736L357.016 28.6258" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M371.232 2.22778L357.422 15.9069" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M343.203 2.35034L357.422 15.9067" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M377.469 19.0762L379.124 37.0491" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M387.684 8.70972L387.398 29.1772" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M407.705 28.7703L387.398 29.1792" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M397.772 38.5843L378.977 38.0322" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M399.984 45.76L387.828 59.4595" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M413.932 44.9438L399.836 59.3181" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M413.79 73.4058L400.797 59.4609" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M400.535 73.6921L387.828 59.4609" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M399 80.0903L378.57 81.1536" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M408.318 89.5184L387.398 89.314" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M387.133 109.781L387.398 89.314" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M377.938 100.681L378.571 81.1538" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M372.034 103.319L356.875 90.0076" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M371.932 116.548L357.305 102.031" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M342.367 116.406L357.975 102.175" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M342.367 102.868L357.71 90.4158" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M336.445 101.07L335.75 81.2979" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M327.049 110.333L326.906 89.7219" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M305.906 89.0259L326.908 89.7211" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M315.852 79.6414L336.996 81.0113" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M313.648 73.692L325.538 59.5835" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M313.383 44.6558L326.641 59.3163" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M299.836 44.1035L314.484 59.3161" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M299.43 73.9774L314.486 59.3169" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M316.406 38.7067L335.467 37.6025" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M306.188 28.7693L327.597 28.6262" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M357.156 0L357.565 117.796" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M298.344 59.4805L415.996 59.3169" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
                <path d="M399.019 17.5232L315.156 101.336" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_963_547">
                    <rect width="116" height="116" fill="currentColor" transform="translate(0 73)"/>
                </clipPath>
                <clipPath id="clip1_963_547">
                    <rect width="46" height="46" fill="currentColor" transform="translate(779 108)"/>
                </clipPath>
                <clipPath id="clip2_963_547">
                    <rect width="164" height="164" fill="currentColor" transform="translate(103 247)"/>
                </clipPath>
                <clipPath id="clip3_963_547">
                    <rect width="118" height="118" fill="currentColor" transform="translate(298)"/>
                </clipPath>
            </defs>
        </svg>
    )
}