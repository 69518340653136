export const moveImageBox = (object1, object2, object1Offset = 0.08, object2Offset = -0.1, minWinSize = 768) => {
    const scrollPosition = window.scrollY;
    const object1Multiplier = window.innerWidth >= minWinSize ? object1Offset : 0;
    const object2Multiplier = window.innerWidth >= minWinSize ? object2Offset : 0;
    object1.current.style.transform = `translateY(${scrollPosition * object1Multiplier}px)`;
    object2.current.style.transform = `translateY(${scrollPosition * object2Multiplier}px)`;
}

export const moveVideoBox = () => (object1, object2, object1Offset = 0.08, object2Offset = -0.1, minWinSize = 768) => {
    const scrollPosition = window.scrollY;
    const object1Multiplier = window.innerWidth >= minWinSize ? object1Offset : 0;
    const object2Multiplier = window.innerWidth >= minWinSize ? object2Offset : 0;
    object1.current.style.transform = `translateY(${scrollPosition * object1Multiplier}px)`;
    object2.current.style.transform = `translateY(${scrollPosition * object2Multiplier}px)`;
}

export const parallax = (element, offset = 0.1, desktopOnly = true) => {
    const scrollPosition = window.scrollY;
    const multiplier = desktopOnly ? (window.innerWidth >= 768 ? offset : 0) : offset;
    element.style.transform = `translateY(${scrollPosition * multiplier}px)`;
};