import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function SvgLogoLocationHolesovice({id, ...rest}) {
    if(!id){
        id = useMemo(() => randomString('logo-location-holesovice'), []);
    }
    return (
        <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 29.9989C0 29.2099 0.639617 28.5703 1.42862 28.5703H58.5736C59.3626 28.5703 60.0022 29.2099 60.0022 29.9989C60.0022 30.7879 59.3626 31.4275 58.5736 31.4275H1.42862C0.639617 31.4275 0 30.7879 0 29.9989Z"
                  fill={`url(#${id})`}
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.4951 0C16.2841 0 16.9237 0.639617 16.9237 1.42862V42.7489C16.9237 43.314 16.5906 43.826 16.0739 44.055C15.5572 44.2839 14.9541 44.1868 14.5355 43.8072L0.469007 31.0527C-0.115498 30.5227 -0.159692 29.6193 0.370296 29.0347C0.900284 28.4502 1.80376 28.406 2.38826 28.936L14.0665 39.525V1.42862C14.0665 0.639617 14.7061 0 15.4951 0Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M58.7958 14.2863C59.5848 14.2863 60.2244 14.9259 60.2244 15.7149V58.5714C60.2244 59.3604 59.5848 60 58.7958 60C58.0068 60 57.3672 59.3604 57.3672 58.5714V15.7149C57.3672 14.9259 58.0068 14.2863 58.7958 14.2863Z"
                  fill="currentColor"/>
            <defs>
                <linearGradient id={id} x1="-1.81496" y1="30.2163" x2="20.8866" y2="58.8688"
                                gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.8" stopColor="currentColor"/>
                    <stop offset="1" stopOpacity="0.04" stopColor="currentColor"/>
                </linearGradient>
            </defs>
        </svg>
    )
}