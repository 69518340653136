import SvgLogoLocationVinohrady from "./SvgLogoLocationVinohrady";
import SvgLogoLocationKarlin from "./SvgLogoLocationKarlin";
import SvgLogoLocationHolesovice from "./SvgLogoLocationHolesovice";
import SvgLogoLocationSmichov from "./SvgLogoLocationSmichov";

export default function SvgLogoLocation({location, ...rest}) {
    switch (location) {
        case 'vinohrady':
            return <SvgLogoLocationVinohrady {...rest}/>;
        case 'karlin':
            return <SvgLogoLocationKarlin {...rest}/>;
        case 'smichov':
            return <SvgLogoLocationSmichov {...rest}/>;
        default:
        case 'holesovice':
            return <SvgLogoLocationHolesovice {...rest}/>;
    }
}