import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function SvgLogoLocationVinohrady({id, ...rest}) {
    if(!id){
        id = useMemo(() => randomString('logo-location-vinohrady'), []);
    }

    return (
        <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.9989 0.000305176C30.7879 0.000305176 31.4274 0.639895 31.4274 1.42887V58.5714C31.4274 59.3604 30.7879 60 29.9989 60C29.2099 60 28.5703 59.3604 28.5703 58.5714V1.42887C28.5703 0.639895 29.2099 0.000305176 29.9989 0.000305176Z"
                  fill={`url(#${id})`}
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.789993 0.151136C1.49567 -0.20171 2.35377 0.0843164 2.70662 0.789993L30.0007 55.3772L56.2601 2.86158L30.0005 2.85744C29.2115 2.85732 28.572 2.21763 28.5722 1.42865C28.5723 0.639678 29.212 0.000188677 30.0009 0.000312968L58.5717 0.00481345C59.0667 0.00489142 59.5265 0.261288 59.7867 0.682447C60.0469 1.10361 60.0706 1.62948 59.8492 2.07228L31.2785 59.2103C31.0365 59.6943 30.5418 60 30.0007 60C29.4596 60 28.965 59.6943 28.723 59.2103L0.151136 2.06776C-0.20171 1.36208 0.0843164 0.503981 0.789993 0.151136Z"
                  fill="currentColor"/>
            <defs>
                <linearGradient id={id} x1="29.9988" y1="58.5715" x2="2.18374" y2="36.53"
                                gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.04" stopColor="currentColor"/>
                    <stop offset="1" stopOpacity="0.8" stopColor="currentColor"/>
                </linearGradient>
            </defs>
        </svg>
    )
}