import Image from "next/image";
import styles from '../styles/components/MapPin.module.scss';
import SvgBasicPin from "../svg/SvgBasicPin";
import SvgTick from "../svg/SvgTick";
import circleImage from '../public/img/icons/circle_white_alt.png';
import SvgLogoSmall from "../svg/SvgLogoSmall";

export default function MapPin({number, top = 0, left = 0, className = '', type = 'loading'}) {

    return (
        <div
            className={`${styles.pinWrapper} absolute text-white pointer-events-none -translate-x-1/2 -translate-y-1/2 w-[10%] ${className}`}
            style={{top, left}}
        >
            <div className={styles.pin}>
                <SvgBasicPin className="w-full"/>

                {type === 'logo' && (
                    <div className="absolute w-[40%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-[80%]">
                        <SvgLogoSmall id="map-pin" width="100%"/>
                    </div>
                )}

                {type !== 'logo' && (
                    <div className={`absolute w-[68%] top-[11%] left-1/2 -translate-x-1/2 inline-flex`}>
                        <div className={`${styles.circle} inline-flex aspect-square`}
                             style={{
                                 animationDelay: number ? `${(number - 1) * 1400}ms` : 'none'
                             }}
                        >
                            <Image src={circleImage}
                                   alt=""
                                   className={type === 'loading' ? 'animate-spin ' : ''}
                            />
                        </div>

                        {number && type === 'loading' && (
                            <span
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-sm md:text-xs lg:text-sm pointer-events-auto text-white/50">{number}</span>
                        )}

                        {type === 'finished' && (
                            <div
                                className={`${styles.tick} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}>
                                <SvgTick width={13}/>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
