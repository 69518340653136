export default function Fadeout({direction = 'right', color = 'black', className = ''}) {
    let directionClasses;
    let colorClasses;

    switch (direction) {
        case 'top':
            directionClasses = 'bottom-0 left-0 bg-gradient-to-t w-full';
            break;
        case 'bottom':
            directionClasses = 'top-0 left-0 bg-gradient-to-b w-full';
            break;
        case 'left':
            directionClasses = 'top-0 right-0 bg-gradient-to-l h-full';
            break;
        case 'right':
        default:
            directionClasses = 'top-0 left-0 bg-gradient-to-r h-full';
            break;
    }

    switch (color) {
        case 'white':
            colorClasses = 'from-white to-white/0';
            break;
        case 'gray':
            colorClasses = 'from-gray-300 to-gray-300/0';
            break;
        case 'black':
        default:
            colorClasses = 'from-black to-black/0';
            break;
    }

    return (
        <div
            className={`absolute z-20 pointer-events-none ${directionClasses} ${colorClasses} ${className}`}
        />
    )
}
