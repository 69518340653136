import SvgBatBg from "../svg/SvgBatBg";
import SvgSnowflakesBg from "../svg/SvgSnowflakesBg";
import SvgLogoLocation from "../svg/SvgLogoLocation";

export default function LocationBgComponent({location, ...rest}) {
    switch (process.env.NEXT_PUBLIC_THEME) {
        case 'halloween':
            return (
                <SvgBatBg className="opacity-[.15] relative left-[200px]" {...rest} />
            );
        case 'christmas':
            return (
                <SvgSnowflakesBg className="opacity-[.15] relative left-[200px]" {...rest} />
            );
        default:
            return (
                <SvgLogoLocation location={location} id="location-bg-logo"
                                 className="relative opacity-10 top-10"
                                 {...rest}
                />
            );
    }
}
